/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "./src/css/custom.scss";

export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  // Se non sto cambiando pagina non aggiornare la scroll position
  if (
    prevRouterProps?.location?.pathname === routerProps?.location?.pathname &&
    !routerProps?.location?.hash
  ) {
    return false;
  }
};

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={process.env.GATSBY_CAPTCHA_CLIENT_KEY}
    language="it"
    // useRecaptchaNet="[optional_boolean_value]"
    // useEnterprise="[optional_boolean_value]"
    scriptProps={{
      async: true,
      defer: true,
    }}
  >
    {element}
  </GoogleReCaptchaProvider>
);
